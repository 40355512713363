.ant-layout-sider {
    background: black !important;

    .ant-menu-dark {
        background: black !important;
    }
}

@media only screen and (max-width: 768px) {
    .ant-table-wrapper {
        min-width: 1000px;
    }
}
