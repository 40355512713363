.blockButton {
    position: relative;
}

.nowIndicator {
    &::after {
        content: ' ';
        border-bottom: 1px dashed red;
        height: 1px;
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        z-index: 3;
    }

    &::before {
        content: ' ';
        height: 10px;
        width: 10px;
        left: -10px;
        border-radius: 999px;
        background-color: red;
        position: absolute;
        top: -5px;
    }
}
